import React, {useState} from 'react';
import * as Styles from '../styles/feedback.module.scss';
import bg from '../images/collage.png';

const Feedback = () => {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [products, setProducts] = useState("");
    const [feedback, setFeedback] = useState("");
    const [submitting, setsubmitting] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setsubmitting(true);
        let formdata = new FormData();
        formdata.append('name', name);
        formdata.append('mobile', mobile);
        formdata.append('products', products);
        formdata.append('feedback', feedback);

        fetch('https://script.google.com/macros/s/AKfycbxF_IWc2bbNgOwqPhE79CIAcuYaYBe5gA45Zs3Nuy3JLo1B2y-nIGDnxIjSwQDRBwk4IQ/exec', {
            method: 'POST',
            body: formdata
        }).then(response => {
            if(response.ok){
                alert("Thank you for sharing your feedback. We will get back to you asap.");
                setName("");
                setMobile("");
                setProducts("");
                setFeedback("");
                setsubmitting(false);
            }
            else {
                alert("There was an errer submitting your request. Please try again later.");
                throw new Error(response);
            }
        }).catch(error=>console.error(error))
    }

    return (
        <section className={Styles.main} id="feedback"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundPosition: "center",
                backgroundSize: '100%'
            }}
        >
            {submitting && <div className={Styles.submitting}><div className="spin"></div></div>}
            <h1>Your Feedback</h1>
            <form>
                <div className={Styles.group}>
                <label htmlFor="name">Name: </label>
                <input type="text" name="name" placeholder="Enter your name" value={name} onChange={e=>setName(e.target.value)}/>
                </div>

                <div className={Styles.group}>
                <label htmlFor="mobile">Mobile :</label>
                <input type="number" name="mobile" placeholder="Mobile number" value={mobile} onChange={e=>setMobile(e.target.value)} required/>
                </div>

                <div className={Styles.group}>
                <label htmlFor="products">Product purchased :</label>
                <textarea name="products" placeholder="Products purchased" value={products} onChange={e=>setProducts(e.target.value)} required/>
                </div>

                <div className={Styles.feedback}>
                <label htmlFor="feedback">Feedback :</label>
                <textarea name="feedback"  placeholder="Enter your feedback" value={feedback} onChange={e=>setFeedback(e.target.value)} required></textarea>
                </div>

                <button onClick={handleSubmit}>Submit</button>
            </form>
        </section>
    );
}

export default Feedback;