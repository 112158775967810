import React from 'react';

import Header from '../components/header';
import Footer from '../sections/footer';
import Seo from '../components/seo';
import Feedback from '../sections/feedback';
// Contexts
import LanguageProvider from '../contexts/language';
import {NavContextProvider} from '../contexts/navigation';

function FeedbackPage(props) {
    return (
        <div>
            <LanguageProvider>
                <NavContextProvider>
                    <Seo title="Feedback"/>
                    <Header/>                  
                    <Feedback/>
                    <Footer/>
                </NavContextProvider>
            </LanguageProvider>
        </div>
    );
}

export default FeedbackPage;